import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Http from './network/network'
import adminHttp from "./network/adminHttp";
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.min.js'
import 'element-ui/lib/theme-chalk/index.css';
import Element from 'element-ui'
import md5 from 'js-md5';
import i18n from "./utils/i18n"

// import Calendar from 'vue-mobile-calendar'
// Vue.use(Calendar);

window.Http = Http
window.adminHttp =adminHttp
Vue.prototype.$md5 = md5;
Vue.config.productionTip = false
Vue.use(Element)


new Vue({
	i18n,
	router,
	store,
	render: h => h(App)
}).$mount('#app')
